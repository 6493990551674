import { LINE, MOBILE, MAP, LOCATION, FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER  } from '../../Assets/SVG';

const ContactUs = () => {
  return (
    <section id="contactus" className='relative font-Pop'>
        <div className="px-3 md:px-6 lg:px-16 pt-5 md:pt-20 pb-6 md:pb-14">
            <div className="flex flex-col justify-center items-center gap-2.5">
                <div className="flex items-center justify-center gap-2">
                    <img src={LINE} alt="line-svg" />
                    <h4 className="tracking-wider text-xs md:text-base">Reach</h4>
                </div>
                <h1 className="font-medium tracking-wider text-xl md:text-5xl text-[#26313C]">Contact US</h1>
            </div>
            <div className='w-full min-h-[60vh] h-auto md:flex md:gap-4 lg:gap-8 mt-6 md:mt-16'>
                <div className='p-5 w-full md:w-3/5 h-full rounded-lg' style={{boxShadow: '3.92px 3.92px 19.6px 0px #0000001A'}}>
                    <div className='text-center mt-5'>
                        <p className='capitalize text-2xl tracking-widest text-[#26313C] font-medium'>get in touch</p>
                        <p className='text-sm tracking-widest mt-3 font-light'>"Your feedback matters to us."</p>
                        <p className='text-sm tracking-widest font-light'>Drop us a message, and Let's connect!</p>
                    </div>
                    <div className='flex justify-between mt-6 md:mt-10 px-12'>
                        <img className='w-10 h-auto' src = {LOCATION} alt='location' />
                        <img className='w-10 h-auto' src={MOBILE} alt='mobile' />
                        <img className='w-10 h-auto' src={MAP} alt='map'/>
                    </div>
                    <div className='w-full h-40 mt-6 md:mt-16 border border-gray-500'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d26041.34871648786!2d77.42750720000001!3d8.1625088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1732266179424!5m2!1sen!2sin" style={{border:'0'}}  title="Google Map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='w-full h-full'></iframe>
                    </div>
                </div>
                <div className='w-full md:w-2/5 h-full px-5 py-8 z-10 bg-white rounded-lg' style={{boxShadow: '3.92px 3.92px 19.6px 0px #0000001A'}}>
                    <div className='px-2'>
                        <label className='text-[#1266E3] tracking-wider text-lg'>Name</label>
                        <input className='input-gradient border pl-2.5 py-2 w-full md:my-2 outline-none' />
            
                        <label className='text-[#1266E3] tracking-wider text-lg'>Email</label>
                        <input className='input-gradient border py-2 pl-2.5 w-full md:my-2 outline-none' />

                        <label className='text-[#1266E3] tracking-wider text-lg'>Phone</label>
                        <input type='number' className='input-gradient border py-2 pl-2.5 w-full md:my-2 outline-none' />

                        <label className='text-[#1266E3] tracking-wider text-lg' >City</label>
                        <input className='input-gradient border py-2 pl-2.5 w-full md:my-2 outline-none' />

                        <label className='text-[#1266E3] tracking-wider text-lg'>Description</label>
                        <textarea className='input-gradient border py-2 pl-2.5 w-full md:my-2 outline-none' cols={8} rows={3} />

                        <div className='w-full flex justify-center pt-2'>
                            <button className='border py-2 px-3 rounded-lg tracking-wider bg-[#26313C] text-white'>Contact Us</button>
                        </div>
                    </div>         
                </div>   
            </div>       
        </div>
        <Footer />
    </section>
  
  );
}
export default ContactUs;

const Footer = () => {

    const scrollToSection = (e) => {
        e.preventDefault();
        const targetId = e.currentTarget.getAttribute("href").slice(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };

    return(
        <div className='md:absolute top-[730px] w-full '>
            <div className='bg-[#26313C] text-white tracking-wider h-64 pt-7'>
                <div className='md:flex justify-around items-baseline w-3/5 grid gap-3'>
                    <a href="/" className="flex items-center gap-2">
                        <span className="text-lg md:text-lg tracking-widest font-medium font-Manrope uppercase text-white">Immanuel </span>
                    </a>
                    <div className='text-base font-normal flex flex-col gap-2'>
                        <p className='text-lg tracking-widest mb-2'>Quick Links</p>
                        <a onClick={scrollToSection} className='font-light cursor-pointer text-sm' href="#aboutus">About Us</a>
                        <a onClick={scrollToSection} className='font-light cursor-pointer text-sm' href="#partners">PartnerShip</a>
                    </div>
                    <div>
                        <p className='text-lg tracking-widest mb-2'>Social Media</p>
                        <div className='flex gap-3 mt-3'>
                            <img className='w-6 h-auto' src = {FACEBOOK} alt='facebook' />
                            <img className='w-6 h-auto' src={INSTAGRAM} alt='instagram' />
                            <img className='w-6 h-auto' src={LINKEDIN} alt='linkedin'/>
                            <img className='w-6 h-auto' src={TWITTER} alt='twitter'/>
                        </div>
                    </div>
                </div>        
            </div>
            <p className='mx-6 md:mx-16 py-3 tracking-widest text-black text-xs md:text-base'>All Rights Reserved By Immanuael</p>
        </div>
    );
};
