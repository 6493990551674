import React from 'react';
import { LINE } from '../../Assets/SVG';
import IMG_1 from "../../Assets/Images/aboutus-1.jpg";
import IMG_2 from "../../Assets/Images/aboutus-2.jpg";

const AboutUs = () => {
  return (
    <section id="aboutus" className="pt-5 md:pt-24 font-Pop">

      <div className="flex flex-col justify-center items-center gap-3">
        <div className="flex items-center justify-center gap-2">
          <img src={LINE} alt="line-svg" />
          <h4 className="tracking-wider text-base text-black">Our Introduction</h4>
        </div>
        <h1 className="font-medium tracking-wider text-xl md:text-5xl text-[#26313C]">About Us</h1>
      </div>

      <div className="mt-6 md:mt-16">
        <div className='grid grid-cols-1 md:grid-cols-4'>
          <div className="col-span-4 lg:col-span-3 px-5 md:px-0 py-7 md:pl-16 md:pr-20 bg-[#D9D9D9]">
            <p className="tracking-widest leading-8 text-justify text-sm font-light" style={{color:'darkslategray'}}>Lorem ipsum dolor sit amet consectetur. Cursus tempus facilisis vel diam enim. Turpis amet auctor nec egestas lacus ultrices. Aliquam posuere amet rhoncus et hendrerit. Sapien eu interdum id dapibus suspendisse nam dictum sed. Eget scelerisque eu nulla justo risus aenean massa. Cursus egestas dui sollicitudin id lobortis purus proin proin ac. Scelerisque integer egestas commodo non odio bibendum mi. Morbi lorem augue hac placerat ipsum tempus amet elementum molestie. Laoreet scelerisque varius fusce pellentesque praesent neque. Leo libero lacus ut commodo. Feugiat sit elementum adipiscing odio fames suspendisse arcu et bibendum. Posuere neque ultricies orci blandit non in. Sem commodo vitae non tristique amet convallis sociis et dignissim. Sit dapibus hendrerit intege</p>
          </div>
          <div className="bg-[#8891A6] py-10 hidden lg:flex">
            <div className="pl-10 pr-14 h-72">
              <img src={IMG_1} className="h-full w-full object-cover" />
            </div>
          </div>
        </div>

        <div className="h-32 md:h-64 overflow-hidden">
          <img src={IMG_2} className="w-full h-full object-cover" />
        </div>
      </div>

    </section>
  )
}

export default AboutUs;