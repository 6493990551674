import { useEffect } from "react";
import Lenis from "lenis";
import Navbar from "./Components/Navbar";
import Hero from "./Pages/LandingPage/Hero";
import Services from "./Pages/LandingPage/Services";
import WorkProcess from "./Pages/LandingPage/WorkProcess";
import AboutUs from "./Pages/LandingPage/AboutUs";
import Partners from "./Pages/LandingPage/Partners";
import Testimonials from "./Pages/LandingPage/Testimonials";
import ContactUs from "./Pages/LandingPage/ContactUs";

function App() {

  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  return (
    <div>
      <Navbar />
      <Hero />
      <Services />
      <WorkProcess />
      <AboutUs />
      <Partners />
      <Testimonials />
      <ContactUs />
    </div>
  );
}

export default App;
