
const WorkProcess = () => {
  return (
    <section className="bg-[#26313C] 2xl:p-16 xl:p-14 lg:p-10 md:p-8 p-8 font-Pop">
    <div className="container mx-auto 2xl:py-12 xl:py-10 lg:py-8 ">
      <h2 className="text-center 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-white font-medium tracking-wider mb-8">
        Our Work Process
      </h2>
      <div className="flex justify-center  2xl:pt-16 xl:pt-14 lg:pt-10 md:py-8 gap-8">
        <div className="flex 2xl:flex-row xl:flex-row lg:flex-row justify-center items-center flex-col gap-8">
          {PROCESS.map((step, index) => (
            <div
              key={index}
              className="flex 2xl:flex-col xl:flex-col lg:flex-col justify-center items-center text-center  2xl:items-start gap-4"
            >
              <div className='flex'>
              <div className="relative 2xl:pl-24 lg:pt-0 xl:pt-0 2xl:pt-0 md:pt-5 sm:pt-4 pt-3">
                <div className="border rounded-full bg-gray-800 z-50 2xl:w-24 xl:w-20 lg:w-16 md:w-12  w-10 h-10 2xl:h-24 xl:h-20 lg:h-16 md:h-12 flex items-center justify-center text-2xl font-bold">
                  <h1 className="text-white 2xl:text-3xl font-thin">0{index + 1}</h1>
                </div>
                {index < PROCESS.length - 1 && (
                  <div className="hidden 2xl:block xl:block lg:block absolute 2xl:left-48 xl:left-20 lg:left-16 2xl:top-12 xl:top-10 lg:top-8 border-b-2 lg:w-40  w-60 xl:w-64 z-0"></div>
                )}
               </div>
              </div>
              <div className="flex flex-col border-l-2 lg:border-none 2xl:border-none mt-4">
                <h1 className="text-xl 2xl:text-2xl xl:text-xl lg:text-lg text-white tracking-wider">{step.title}</h1>
                <h1 className="text-xs tracking-widest text-center text-white mt-3 px-4 font-extralight">{step.description}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
  );
};

export default WorkProcess;

const PROCESS = [
  { title: "Meet Consultant", description: "apien eu interdum id dapibus suspendisse nam dictum sed" },
  { title: "Expert Advice", description: "apien eu interdum id dapibus suspendisse nam dictum sed" },
  { title: "Development", description: "apien eu interdum id dapibus suspendisse nam dictum sed" },
  { title: "Testing", description: "apien eu interdum id dapibus suspendisse nam dictum sed" },
  { title: "Delivery", description: "apien eu interdum id dapibus suspendisse nam dictum sed" }
];