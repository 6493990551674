import React from 'react';
import { LINE } from '../../Assets/SVG';
import ACS from "../../Assets/Images/acs-logo.jpg";
import ELOIACS from "../../Assets/Images/eloiacs-logo.jpg";
import BANNER from "../../Assets/Images/partners.jpg";

const Partners = () => {
  return (
    <section id="partners" className="pt-5 md:pt-24 font-Pop">

      <div className="flex flex-col justify-center items-center gap-3">
        <div className="flex items-center justify-center gap-2">
          <img src={LINE} alt="line-svg" />
         <h4 className="tracking-wider text-base text-black">In Collaboration</h4>
        </div>
        <h1 className="font-medium tracking-wider text-xl md:text-5xl text-[#26313C]">Our Partners</h1>
      </div>

      <div className="px-0.5 md:px-16 mt-5 md:mt-16 grid grid-cols-3 gap-3 md:gap-0">
        <div className="flex items-center justify-center" >
          <div className="flex items-center justify-center h-24 md:w-52 md:h-52 rounded-md">
            <a href="https://eloiacs.com/" target="_blank">
              <img src={ELOIACS} />
            </a>
          </div>
        </div>

        <div className="flex items-center justify-center" >
          <div className="flex items-center justify-center h-24 md:w-52 md:h-52 rounded-md">
            <a href="https://acsgroups.co.in/" target="_blank" className="">
              <img src={ACS} />
            </a>
          </div>
        </div>

        <div className="flex items-center justify-center" >
          <div className="flex items-center justify-center h-24 md:w-52 md:h-52 rounded-md">
            <a href="https://eloiacs.com/" target="_blank">
              <img src={ELOIACS} />
            </a>
          </div>
        </div>
      </div>

      <div className="mt-8 md:mt-24 flex justify-center text-center">
        <p className="tracking-widest text-sm md:text-lg" style={{ color:'darkslategray' }}>"Join our network of partners and drive innovation together."</p>
      </div>

      <div className="mt-6 flex justify-center">
        <button className="px-5 py-2.5 bg-[#26313C] text-white text-sm rounded-full tracking-widest">Reach us</button>
      </div>

      <div className="mt-6 md:mt-20 h-32 md:h-64 overflow-hidden">
        <img src={BANNER} className='h-full w-full' />
      </div>
      
    </section>
  )
}

export default Partners;